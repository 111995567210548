import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styles from "./index.module.css"

import Container from "../components/container.js"

const Portraits = () => {
	
	return (
		<div>
			<Container>
				
			</Container>
		</div>
	)
}

export default Portraits